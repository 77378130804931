import React from "react";
import { useParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/system";
import { Skeleton, styled, Tab, Tabs, Typography } from "@mui/material";
import { LocaleContext } from "contexts/LocaleContext";
import { fetchFireAlarmSystem } from "adapters/fireAlarmSystem";
import SensorInfo from "components/diagnostics/SensorInfo";


const Diagnostics: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const params = useParams();
    const alarmSystemId = params.id || "alarm";
    const [selectedDiagnositcsTab, setDiagnosticsTab] = React.useState("sensor-info")
    const {
        data,
        isLoading
    } = useQuery({
        queryKey: [alarmSystemId],
        queryFn: async () => {
            const response = await fetchFireAlarmSystem(alarmSystemId);
            return {
                data: response.data,
            };
        },
    });

    const TabsWithBorder = styled(Tabs)
        (({ theme }) => ({
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            background: theme.palette.background.paper
        })
        );

    const renderTabContent = (): React.ReactNode => {
        switch (selectedDiagnositcsTab) {
            case "sensor-info":
                return <SensorInfo />
            default:
                return <SensorInfo />
        }
    }


    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{
                    marginBottom: "1.375em",
                    color: (theme) => theme.palette.primary.main,
                    display: { xs: "none", sm: "inherit" }
                }}>
                    {isLoading ? <Skeleton
                        width="10rem" /> : `${data?.data.name} / ${localize("navigation.operations")} / ${localize("navigation.diagnostics")}`}
                </Typography>
            </Box>
            <TabsWithBorder variant="scrollable" value={selectedDiagnositcsTab} onChange={(event, newValue) => setDiagnosticsTab(newValue)}>
                <Tab key={0} value={"sensor-info"} label={localize('navigation.sensor-info')} />
            </TabsWithBorder>
            {renderTabContent()}
        </>
    );
};

export default Diagnostics;