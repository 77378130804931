import React, { useEffect, useState } from "react";
import { createSensorInfoCommand, fetchSensorInfoCommand, fetchSensorInfo } from "adapters/fireAlarmSystem";
import { Skeleton } from "@mui/material";
import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isLastInArray } from "utils/arrays";
import { useInView } from "react-intersection-observer";
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "components/StyledComponents/Table";
import { LocaleContext } from "contexts/LocaleContext";
import useDateFormat from "hooks/useDateFormat";
import { CommandStatus, ICreateSensorInfoCommand } from "interfaces/fireAlarmSystem";
import { SnackBarContext } from "contexts/SnackBarContext";
import { useSearchParams } from "react-router-dom";
import useGetAlarmSystem from "hooks/useGetAlarmSystem";
import { getMacAddress } from "utils/getMacAdress";
import SensorInfoTableFotterRow from "./SensorInfoTableFotterRow";
import useGetNewProgressOfStatus from "hooks/useGetNewProgressOfStatus";


const LoadingEventSkelton: React.FC = () => {
    const loadingCells = Array.from({ length: 10 }, (_, index) => (
        <StyledTableCell key={index}>
            <Skeleton />
        </StyledTableCell>
    ));

    return (
        <StyledTableRow>
            {loadingCells}
        </StyledTableRow >
    );
};


const SensorInfo: React.FC = () => {
    const { localize } = React.useContext(LocaleContext);
    const { ref, inView } = useInView()
    const { formatDate } = useDateFormat();
    const [pageDirection, setPageDirection] = React.useState(0);
    const { alarmSystem, isLoading } = useGetAlarmSystem();
    const [lastTechNr, setLastTechNr] = React.useState("000001")
    const [lastUpdatedAt, setLastUpdatedAt] = React.useState("")
    const [currentExecutingCommand, setCurrentExecutingCommand] = React.useState<string>("");
    const [currentProgress, setcurrentProgress] = React.useState(0);
    const [isExecuting, setIsExecuting] = React.useState(false);
    const queryClient = useQueryClient();
    const { openSnack } = React.useContext(SnackBarContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalSensorItemsCount, setTotalSensorItemsCount] = useState<number>(0);
    const alarmSystemId = sessionStorage.getItem("alarm_system_id");

    const sensorInfoQuery = useInfiniteQuery({
        queryKey: [`sensor-info-${alarmSystemId}`],
        queryFn: async ({ pageParam }) => {
            const params = {
                fire_alarm_system: alarmSystemId,
                page: pageParam,
            };
            const res = await fetchSensorInfo({ params: params })
            return {
                data: res.data.results,
                nextPage: res.data.next ? pageParam + 1 : undefined,
            }
        },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        enabled: !!alarmSystemId
    });

    React.useEffect(() => {
        if (inView) {
            sensorInfoQuery.fetchNextPage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);


    interface Columns { text: string }

    const columns: Columns[] = [
        {
            text: "type"
        },
        {
            text: "tech-no"
        },
        {
            text: "zone-address"
        },
        {
            text: "momentary"
        },
        {
            text: "weekly"
        },
        {
            text: "perf-factor"
        },
        {
            text: "min"
        },
        {
            text: "max"
        },
        {
            text: "algorithm"
        },
        {
            text: "install-date"
        },
        {
            text: "month-left"
        }
    ]

    const handleUpdateQuery: () => void = () => {
        createOperation(getArgs());
        sensorInfoQuery.refetch();
    };

    useEffect(() => {
        if (sensorInfoQuery.data?.pages && sensorInfoQuery.data.pages.length !== 0) {
            const totalCount = sensorInfoQuery.data.pages.reduce((accumulatedCount, datablock) => {
                const countForPage = datablock.data.reduce((pageCount, items) => {
                    if (items) {
                        const QItems = items.payload.sensor_items;
                        return pageCount + (Array.isArray(QItems) ? QItems.length : 0);
                    }
                    return 0
                }, 0);

                return accumulatedCount + countForPage;
            }, 0);
            setTotalSensorItemsCount(totalCount);

            (sensorInfoQuery.data?.pages.map((datablock, i) => {
                const lastItemIndex = datablock.data.length - 1
                const lastInfoItem = datablock.data[lastItemIndex]
                if (lastInfoItem !== undefined) {
                    const lastSensorItem = lastInfoItem.payload.sensor_items.length - 1

                    if (pageDirection !== 0 && lastSensorItem >= 0) {
                        const lastSensorItemIndex = lastInfoItem.payload.sensor_items[lastSensorItem]
                        setLastTechNr(lastSensorItemIndex['techno'])
                    }
                    if (lastSensorItem < 0) {
                        setPageDirection(0)
                        setLastTechNr("000001")
                    }
                    setLastUpdatedAt(formatDate(lastInfoItem.datetime))
                }
            }
            ))
        }
    }, [sensorInfoQuery.data?.pages]);

    const getNewProgressOfStatus = useGetNewProgressOfStatus;

    useQuery({
        queryKey: ["command", currentExecutingCommand],
        queryFn: async () => {
            const queryResult = await fetchSensorInfoCommand(currentExecutingCommand)
            const commandStatus = queryResult.data.status;
            setcurrentProgress((current) => getNewProgressOfStatus(current, commandStatus));
            if (commandStatus === CommandStatus.COMPLETED) {
                resetState();
                onSuccess();
                queryClient.invalidateQueries({ queryKey: [`sensor-info-${alarmSystemId}`] }, { cancelRefetch: true });
            } else if (commandStatus === CommandStatus.FAILED) {
                resetState();
                onError()
            }
            return queryResult ?? null
        },
        enabled: currentExecutingCommand !== "",
        refetchInterval: 1000
    });

    const resetState = () => {
        setIsExecuting(false);
        setcurrentProgress(0);
        setCurrentExecutingCommand("");
    };

    const onSuccess = () => {
        setPageDirection(2)
        openSnack(localize(`sensor-info.fetching-completed-successfully`), "success");
    };

    const onError = () => {
        openSnack(localize(`sensor-info.fetching-failed`), "warning");
        setSearchParams({ ...searchParams, modal: 'operations-error' })
    };

    const commandMutation = useMutation({
        mutationFn: createSensorInfoCommand,
        onMutate: () => {
            setIsExecuting(true);
        },
        onSuccess: (res) => {
            setCurrentExecutingCommand(res.data.id)
        },
        onError: (e) => {
            resetState();
            onError();
        }
    })


    const createOperation = (data: ICreateSensorInfoCommand) => {
        commandMutation.mutate(data);
    };

    const refreshInterval = 10000;

    useEffect(() => {
        const intervalId = setInterval(() => {
            queryClient.invalidateQueries({ queryKey: ["fire-alarm-system", alarmSystemId] })
        }, refreshInterval);

        return () => clearInterval(intervalId);
    }, [alarmSystemId, queryClient]);

    const communicationError = alarmSystem?.deviations.communication_error
    const communicationErrorExists = (communicationError ?? []).length >= 1 ? true : false

    const activeGateway = alarmSystem?.gateways.find(gw => gw.disconnected === null);
    const gatewayNotConnected = activeGateway?.connected === false || !activeGateway

    const getArgs = (): ICreateSensorInfoCommand => {
        return {
            fire_alarm_system: alarmSystemId,
            version: 0,
            type: "cmd",
            source: getMacAddress(alarmSystem),
            user: "",
            seq_ack: 0,
            subject: "cmd/sensorinfo/request",
            payload: {
                cmdtype: "execute",
                jobid: "",
                data: {
                    parameters: {
                        techno: lastTechNr,
                        dir: pageDirection,
                    }
                }
            }
        }
    };


    return (
        <>
            <StyledTableContainer sx={{ height: '73vh' }} >
                <Table sx={{ width: '100%', overflow: 'hidden' }} size="small" stickyHeader >
                    <TableHead>
                        <TableRow>
                            {columns.map((entry) => (
                                <StyledTableCell
                                    style={{ wordBreak: "normal" }}
                                    key={entry.text}
                                >
                                    {localize(`sensor-info.${entry.text}`)}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sensorInfoQuery.data?.pages.every(datablock => datablock.data.length === 0) ? (
                            <StyledTableRow key={"no-data"} >
                                <StyledTableCell colSpan={columns.length}>{localize("sensor-info.no-data-available")}</StyledTableCell>
                            </StyledTableRow>
                        ) : sensorInfoQuery.data?.pages.map((datablock, i) => datablock.data.map((sensorInfo, j) => (
                            sensorInfo.payload.sensor_items.map((sensor_item, z) => (
                                <StyledTableRow
                                    key={z}
                                    ref={isLastInArray(sensorInfoQuery.data?.pages, i) && isLastInArray(datablock.data, j) ? ref : null}
                                >

                                    <StyledTableCell >{sensor_item.type}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.techno}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.zoneaddress}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.mnt}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.week}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.perf}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.min}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.max}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.algo}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.instdate}</StyledTableCell>
                                    <StyledTableCell >{sensor_item.mnleft}</StyledTableCell>

                                </StyledTableRow>
                            ))
                        )))}
                        {sensorInfoQuery.isFetchNextPageError && ref != null && (
                            <LoadingEventSkelton />
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>


            <SensorInfoTableFotterRow totalCount={totalSensorItemsCount}
                handleUpdateQuery={handleUpdateQuery} isExecuting={isExecuting} currentProgress={currentProgress}
                lastUpdatedAt={lastUpdatedAt} pageDirection={pageDirection} gatewayNotConnected={gatewayNotConnected}
                communicationErrorExists={communicationErrorExists} isLoading={isLoading} />
        </>
    )
}

export default SensorInfo;